import {
  BannerOptions,
  SEOType,
  ServicesWeHelpOptions,
  SolutionsOptions,
  StatsContainerOptions,
  WhatWeDoOptions,
  WhyChooseUsOptions,
} from "@/types";
import loadable from "@loadable/component";
import React, { FC } from "react";
import Layout from "../App/Layout";
import SEO from "../SEO";
import Banner from "../Shared/Design/Banner";
import FrequentlyAskedQuestions from "../Shared/Design/FrequentlyAskedQuestions";
import { StructuredDataSnippetTag } from "../Shared/StructuredDataTag";

const OurSolutions = loadable(() => import("../Shared/Design/OurSolutions"));
const ServicesWeCanHelp = loadable(() => import("../Shared/Design/ServicesWeCanHelp"));
const Stats = loadable(() => import("../Shared/Design/Stats"));
const WhatWeDo = loadable(() => import("../Shared/Design/WhatWeDo"));
const WhyChooseUs = loadable(() => import("../Shared/Design/WhyChooseUs"));

const StartProject = loadable(() => import("../Shared/extra/StartProject"));

interface HomeProps {
  props: {
    banner: BannerOptions;
    servicesSolutions: SolutionsOptions;
    whyChooseUs: WhyChooseUsOptions;
    whatWeDo: WhatWeDoOptions;
    statsContainer: StatsContainerOptions;
    servicesWeHelpWith: ServicesWeHelpOptions;
    seo: SEOType;
    footerContent?: any;
    frequentlyAskedQuestions: { question: string; id: string; answer: { answer: string } }[];
    structuredDataSnippets: any;
  };
}

const Home: FC<HomeProps> = ({
  props: {
    seo,
    banner,
    servicesSolutions,
    whyChooseUs,
    whatWeDo,
    statsContainer,
    servicesWeHelpWith,
    footerContent,
    frequentlyAskedQuestions,
    structuredDataSnippets,
  },
}) => {
  return (
    <Layout footerContent={footerContent}>
      <StructuredDataSnippetTag snippets={structuredDataSnippets} />
      <SEO contentfulSeo={seo} />
      <Banner props={banner} />
      <OurSolutions props={servicesSolutions} />
      <WhyChooseUs props={whyChooseUs} />
      <WhatWeDo props={whatWeDo} />
      <Stats props={statsContainer} />
      <ServicesWeCanHelp props={servicesWeHelpWith} />
      <br />
      <FrequentlyAskedQuestions list={frequentlyAskedQuestions} />
      <StartProject />
    </Layout>
  );
};

export default Home;
