import { graphql, StaticQuery } from "gatsby";
import React, { FC } from "react";
import HomePage from "../components/Home/index";

const Home: FC = () => {
  return (
    <StaticQuery
      query={graphql`
        query GET_HOME_PAGE {
          contentfulHomePage {
            #       SEO Tags
            seo {
              metaTitle
              metaDescription
              metaUrl
              metaAuthor
              metaKeywords
              metaImage {
                file {
                  url
                }
              }
            }

            #       Banner
            banner {
              title
              content
              backgroundImageFormation {
                file {
                  url
                }
                title
              }
              redirectButtons {
                link
                name
              }
            }

            #   Our  Solutions

            servicesSolutions {
              heading
              description
              servicesLists {
                id
                title
                description
                flaticon {
                  iconName
                }
              }
            }

            #     Why Choose Us

            whyChooseUs {
              heading
              description {
                description
              }
              backgroundImage {
                file {
                  url
                }
              }
              ourClients {
                id
                clientTitle
                clientIcon {
                  file {
                    url
                  }
                }
                clientLink
              }
            }

            #What we do
            whatWeDo {
              heading
              description {
                description
              }
              whatWeDoList {
                id
                title
                description
                redirectLink
                flaticon {
                  iconName
                }
              }
            }

            #     Stats
            statsContainer {
              stats {
                id
                title
                description
                icon {
                  file {
                    url
                  }
                }
              }
            }

            #     Services we help with
            servicesWeHelpWith {
              heading
              description {
                description
              }
              servicesWeCanHelpLists {
                id
                title
                description
                serviceIcon {
                  file {
                    url
                  }
                }
                background {
                  file {
                    url
                  }
                }
              }
            }

            # FAQ
            frequentlyAskedQuestions {
              id
              question
              answer {
                answer
              }
            }

            structuredDataSnippets {
              snippet {
                id
                snippet
              }
            }

            # Footer Content
            footerContent {
              raw
            }
          }
        }
      `}
      render={({
        contentfulHomePage: {
          banner,
          servicesSolutions,
          whyChooseUs,
          whatWeDo,
          statsContainer,
          servicesWeHelpWith,
          seo,
          footerContent,
          frequentlyAskedQuestions,
          structuredDataSnippets,
        },
      }) => {
        return (
          <HomePage
            props={{
              seo,
              banner,
              servicesSolutions,
              whyChooseUs,
              whatWeDo,
              statsContainer,
              servicesWeHelpWith,
              footerContent,
              frequentlyAskedQuestions,
              structuredDataSnippets,
            }}
          />
        );
      }}
    />
  );
};

export default Home;
