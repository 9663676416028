import { BannerOptions } from "@/types";
import { Link } from "gatsby";
import React, { FC } from "react";
import ReactWOW from "react-wow";

interface BannerProps {
  props: BannerOptions;
}

const Banner: FC<BannerProps> = ({
  props: { backgroundImageFormation, content, redirectButtons, title },
}) => {
  const getImg = (name) => backgroundImageFormation.find(({ title }) => title === name)?.file?.url;

  const Banner4Shape1 = getImg("banner-four-shape1");

  const Banner4Shape2 = getImg("banner-four-shape2");

  const Banner4Shape3 = getImg("banner-four-shape3");

  const Banner4Shape4 = getImg("banner-four-shape4");

  const Banner4Shape5 = getImg("banner-four-shape5");

  const Banner4Shape6 = getImg("banner-four-shape6");

  const Banner4Shape7 = getImg("banner-four-shape7");

  const Banner4Shape8 = getImg("banner-four-shape8");

  const Banner4Shape9 = getImg("banner-four-shape9");

  const Banner4Shape10 = getImg("banner-four-shape10");

  const Banner4MainImage = getImg("banner-four-main-img");

  return (
    <div className="banner-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 col-md-12">
            <div className="banner-wrapper-content">
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h1>{title}</h1>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInLeft">
                <p>{content}</p>
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInRight">
                <div className="btn-box">
                  {redirectButtons?.map(({ link, name }, i) => {
                    if (link.includes("http")) {
                      return (
                        <a
                          key={i}
                          href={link}
                          target="_blank"
                          className="default-btn"
                          rel="noreferrer"
                        >
                          <i className="flaticon-view"></i>
                          {name}
                        </a>
                      );
                    }

                    if (link.includes("tel")) {
                      return (
                        <a key={i} href={link} className="default-btn" id="home_call_now_btn">
                          <i className="flaticon-right"></i>
                          {name}
                        </a>
                      );
                    }
                    return (
                      <Link key={i} to={link} className="default-btn">
                        <i className="flaticon-right"></i>
                        {name} <span></span>
                      </Link>
                    );
                  })}
                </div>
              </ReactWOW>
            </div>
          </div>

          <div className="col-lg-7 col-md-12">
            <div className="banner-wrapper-animation-image">
              <ReactWOW delay=".1s" animation="fadeInDown">
                <img src={Banner4Shape1} alt="bg-slice" />
              </ReactWOW>
              <ReactWOW delay=".1s" animation="fadeInDown">
                <img src={Banner4Shape2} alt="Banner4Shape" />
              </ReactWOW>
              <ReactWOW delay=".1s" animation="fadeInDown">
                <img src={Banner4Shape3} alt="Banner4Shape" />
              </ReactWOW>
              <ReactWOW delay=".1s" animation="fadeInDown">
                <img src={Banner4Shape4} alt="Banner4Shape" />
              </ReactWOW>
              <ReactWOW delay=".1s" animation="fadeInDown">
                <img src={Banner4Shape5} alt="Banner4Shape" />
              </ReactWOW>
              <ReactWOW delay=".1s" animation="fadeInUp">
                <img src={Banner4Shape6} alt="Banner4Shape" />
              </ReactWOW>
              <ReactWOW delay=".1s" animation="fadeInDown">
                <img src={Banner4Shape7} alt="Banner4Shape" />
              </ReactWOW>
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <img src={Banner4Shape8} alt="Banner4Shape" />
              </ReactWOW>
              <ReactWOW delay=".1s" animation="fadeInDown">
                <img src={Banner4Shape9} alt="Banner4Shape" />
              </ReactWOW>
              <ReactWOW delay=".1s" animation="fadeInDown">
                <img src={Banner4Shape10} alt="Banner4Shape" />
              </ReactWOW>

              <ReactWOW delay=".1s" animation="fadeInUp">
                <img src={Banner4MainImage} alt="Banner4MainImage" />
              </ReactWOW>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
